<template>
  <div class="auth">

    <div class="bg-content">
      <v-container>
        <v-row>
          <v-col cols="12">

            <div class="mb-2">
              <img alt="" src="../../assets/logo2.png"/>
            </div>

            <form @submit.prevent="login">

              <h2 class="mb-2">
                Accédez à votre espace
              </h2>

              <p class="mb-8 grey--text text--darken-2">
                Veuillez vous connecter à votre compte et commencer l'aventure
              </p>

              <v-alert v-if="error"
                       color="red"
                       dark
                       icon="mdi-information-outline"
                       dismissible
              >
                Vérifiez vos identifiants
              </v-alert>

              <v-text-field outlined
                            placeholder="Entrez votre nom d'utilisateur"
                            label="Nom d'utilisateur *"
                            prepend-inner-icon="mdi-account-circle-outline"
                            type="text"
                            color="gifty"
                            v-model="form.username"
                            :error-messages="error ? error.username : ''"
                            @input="error && error.username ? (error.username = '') : false"
              >
              </v-text-field>

              <div class="text-end mb-2">
                <router-link to="/login"
                             class="text-decoration-none gifty--text">
                  Mot de passe oubliée ?
                </router-link>
              </div>

              <v-text-field outlined
                            color="gifty"
                            placeholder="Entrez votre mot de passe"
                            prepend-inner-icon="mdi-lock-outline"
                            v-model="form.password"
                            :error-messages="error ? error.password : ''"
                            :type="show ? 'text' : 'password'"

                            @click:append="show = !show"
                            @input="error && error.password ? (error.password = '') : false"
                            label="Mot de passe *"
              >
                <template #append>
                  <v-icon color="gifty" @click="show = !show">
                    {{ show ? 'mdi-eye' : 'mdi-eye-off' }}
                  </v-icon>
                </template>
              </v-text-field>

              <v-checkbox class="pa-0 ma-0" label="Se souvenir de moi"></v-checkbox>

              <v-btn type="submit"
                     depressed
                     :loading="btnLoading"
                     large
                     dark
                     block
                     color="gifty"
                     class="mt-3"
              >
                Connexion
              </v-btn>

            </form>

          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="bg-img">
      <div class="content">

        <h1 :style="{fontSize: '40px'}">
          Bienvenue sur GIFTY DZ
        </h1>

        <p class="py-3 f-17" :style="{opacity: 0.85}">
          Gifty, l’appli qui répond à tous vos besoins quotidiens avec des remises exclusives sur 100+ enseignes
          nationales. Économisez avec le cashback instantané et payez vos factures et abonnements en un clin d’œil, y
          compris Sonelgaz, SEAAL, internet et plus encore.
        </p>

        <v-btn variant="flat"
               href="https://gifty.dz/"
               target="_blank"
               depressed
               color="primary"
               height="40">
         <span class="text-white">
            En savoir plus
         </span>
        </v-btn>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      btnLoading: false,
      error: null,
      form: {},
      show: false,
    };
  },
  methods: {
    login() {
      this.btnLoading = true;
      this.error = null;
      axios.post(process.env.VUE_APP_BASE_URL + "auth/login", this.form)
          .then((res) => {
            this.$store.dispatch("isLoggedIn", true)
            this.$store.dispatch("user", res.data)
            this.$store.dispatch('stores', [])
            this.btnLoading = false
            this.$router.go(0);
          })
          .catch((err) => {
            this.btnLoading = false;
            this.error = err.response.data.error;
            console.log(err);
          });
    },
  },
};
</script>

<style scoped>


.auth {
  display: flex;
  height: 100vh;
}

.bg-content {
  width: 40%;
  height: 100%;
  padding: 50px;
  background: #FFFFFF;
}

.bg-img {
  position: relative;
  width: 60%;
  height: 100%;
  background-image: url("https://gifty.dz/wp-content/uploads/2024/07/achats-paiements-par-telephone.webp");
  background-size: cover;
  background-position: center;
}

.bg-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(214, 12, 86, 0.9);
}

.content {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-20%, -50%);
  color: #FFFFFF;
}


@media (max-width: 768px) {
  .auth {
    flex-direction: column;
  }

  .bg-content {
    width: 100%;
    height: 100%;
  }

  .bg-img {
    display: none;
  }
}

@media (max-width: 768px) {
  .auth {
    flex-direction: column;
  }

  .bg-content {
    width: 100%;
    height: 100%;
  }

  .bg-img {
    display: none;
  }
}
</style>
